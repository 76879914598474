<template>
  <div class="user">
    <div class="g-layout">
      <div class="g-layout-l" style="margin-left: 0">
        <div class="g-layout-l-nav">
          <div class="m-card">
            <div class="user-nav">
              <h3>个人中心</h3>
              <p @click="goLink(item.link)" v-for="(item, index) in linkArr1" :key="'linkArr1' + index" :class="{ active: $route.path.indexOf(item.link) !== -1 }">
                {{ item.title }}
              </p>
            </div>
            <div class="user-nav">
              <h3>联系我们</h3>
              <p @click="goLink(item.link)" v-for="(item, index) in linkArr2" :key="'linkArr2' + index" :class="{ active: $route.path.indexOf(item.link) !== -1 }">
                {{ item.title }}
              </p>
            </div>
            <div class="user-nav">
              <h3>账户管理</h3>
              <p @click="goLink(item.link)" v-for="(item, index) in linkArr3" :key="'linkArr3' + index" :class="{ active: $route.path.indexOf(item.link) !== -1 }">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="g-layout-r">
        <router-view />
      </div>
    </div>
    <talkWrap v-if="talkShow" :isUser="true"></talkWrap>
    <!-- <gSide></gSide> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import gSide from "@components/gSide/gSide.vue";
import talkWrap from "@components/chat-wrap/index.vue";

export default {
  name: "user",
  components: { gSide, talkWrap },
  data() {
    return {
      talkShow: false,
      // linkArr1: [
      //   { title: "首页", link: "/user/index" },
      //   { title: "我的证件", link: "/user/card" },
      //   { title: "我的预约", link: "/user/order" },
      //   { title: "我的询盘", link: "/user/inquiry" },
      //   { title: "我的供需", link: "/user/need" },
      //   { title: "我的活动", link: "/user/act" },
      //   { title: "我的关注", link: "/user/follow" },
      //   { title: "我的消息", link: "/user/msg" },
      // ],
      linkArr2: [
        { title: "联系我们", link: "/user/customer" },
        // { title: "意见反馈", link: "/user/idea" },
      ],
      linkArr3: [
        { title: "我的信息", link: "/user/info" },
        { title: "绑定授权", link: "/user/bindAuth" },
        { title: "修改密码", link: "/user/password/user" },
      ],
    };
  },
  mounted() {
    this.$Bus.$off("talkClose");
    this.$Bus.$on("talkClose", () => {
      this.talkShow = false;
    });
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
      enabledExhibitionVisitorReg: "getEnabledExhibitionVisitorReg",
      exhibitionConfig: "getExhibitionConfig",
    }),
    userRole() {
      return this.user && this.user.inMember && this.user.inMember.userRole ? this.user.inMember.userRole : "";
    },
    enabledSupplyDemand() {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.feature.enabledSupplyDemand;
    },
    linkArr1() {
      let arr = [
        { title: "首页", link: "/user/index" },
        { title: "我的洽谈", link: "talk" },
        { title: "我的预约", link: "/user/order" },
        { title: "我的询盘", link: "/user/inquiry" },
        { title: "我的供需", link: "/user/need" },
        { title: "我的活动", link: "/user/act" },
        { title: "我的关注", link: "/user/follow" },
        { title: "我的消息", link: "/user/msg" },
      ];
      if (this.enabledExhibitionVisitorReg) {
        arr = [
          { title: "首页", link: "/user/index" },
          { title: "我的洽谈", link: "talk" },
          { title: "我的证件", link: "/user/card" },
          { title: "我的预约", link: "/user/order" },
          { title: "我的询盘", link: "/user/inquiry" },
          { title: "我的供需", link: "/user/need" },
          { title: "我的活动", link: "/user/act" },
          { title: "我的关注", link: "/user/follow" },
          { title: "我的消息", link: "/user/msg" },
        ];
      }
      if (!this.enabledSupplyDemand) {
        arr.forEach((c, i) => {
          if (c.link === "/user/need") {
            arr.splice(i, 1);
          }
        });
      }
      return arr;
    },
  },
  created() {
    console.log(this.path);
    if (this.userRole == "EXHIBITOR") {
      // 展商身份
      this.linkArr3 = [
        // { title: "我的信息", link: "/user/info" },
        { title: "绑定授权", link: "/user/bindAuth" },
        { title: "修改密码", link: "/user/password/user" },
      ];
    }
  },
  methods: {
    ...mapActions({}),
    goLink(link) {
      if (link == "talk") {
        if(this.getRoleHandle('L1ExShow-L2IM-L3IM')){
          this.talkShow = true;
        }
        return;
      }
      if (link == "/user/order" || link == "/user/inquiry" || link == "/user/need") {
        if (this.user && this.user.inMember.isBlacklist) {
          this.$Message.warning("您已被屏蔽");
          return;
        }
      }
      this.$router.push(`${link}?exhibitionId=${this.exhibitionId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.user {
  width: 1200px;
  margin: 0 auto;
}
.g-layout {
  &-l {
    display: flex;
    width: 170px;
    flex-shrink: 0;
  }
  &-l-nav {
    width: 100%;
  }
  .user-nav {
    padding: 20px;
    border-bottom: 1px solid #f7f7f7;
    h3 {
      font-size: 16px;
      color: #333;
    }
    p {
      font-size: 14px;
      padding-top: 18px;
      cursor: pointer;
    }
    p.active,
    p:hover {
      @include font_color(#1890ff);
    }
  }
}
.user-info {
  padding: 40px;
  display: flex;
  &-l {
    display: flex;
    flex: 1;
    &-left {
      margin-right: 14px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: block;
      }
      .btn {
        margin: 16px 6px;
        background-color: #f7f7f7;
      }
    }
    &-right {
      h3 {
        font-size: 16px;
        color: #333;
        font-weight: normal;
        margin-bottom: 4px;
        span {
          margin-left: 8px;
          font-size: 12px;
          color: #666;
        }
      }
      p {
        font-size: 12px;
        color: #999;
        margin-bottom: 4px;
      }
    }
  }
  &-r {
    width: 150px;
    .card {
      width: 140px;
      height: 120px;
      background-color: #fff;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
  }
}
</style>
